#operations {
    padding: 120px 0px 150px 0px;
    background: linear-gradient(45deg, #ffffffdc, #ffffff) 0 0 / 1000% no-repeat, url(../img/operation.png) 0 0 / cover no-repeat;
}

#operation-title {
    display: grid;
    justify-content: center;
}

#operation-title h1 {
    text-align: center;
    color: #F7811D;
}

#operation-line {
    justify-self: center;
    width: 60%;
    height: 3px;
    background-color: #9c9c9c;
    border-radius: 20px;
}

#operations .card-group {
    padding: 100px 0px 0px 0px;
}

#operations #card-body {
    display: grid;
    gap: 20px;
    background-color: #ffffff;
}

#one {
    justify-content: center;
    gap: 60px;
}

#operations #card-1 {
    width: 100%;
    padding: 40px 20px;
    text-align: center;
    border: 0px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.432);
    transition: 0.5s;
}

#operations #card-1:hover {
    transform: scale(1.05);
}

#operations #card-2 {
    padding: 40px 20px;
    margin: 50px 0px 0px 0px;
    text-align: center;
    border: 0px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.432);
    transition: 0.5s;
}

#operations #card-2:hover {
    transform: scale(1.05);
}

#operations #card-3 {
    padding: 40px 20px;
    width: 46%;
    margin: -10px 0px 0px 340px;
    text-align: center;
    border: 0px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.432);
    transition: 0.5s;
}

#operations #card-3:hover {
    transform: scale(1.05);
}

#operation-content .card-title {
    color: #F7811D;
    font-size: 2rem;
    font-weight: 400;
}

#operation-content .card-text {
    font-size: 1.2rem;
    font-weight: 300;
}

@media(max-width: 1200px) {
    #one {
        gap: 50px;
    }

    #col-one,
    #col-two,
    #col-three {
        display: grid;
        justify-content: center;
    }

    #operation-content #card-1 {
        padding: 30px;
        width: 50vw;
    }

    #operation-content #card-2 {
        padding: 30px;
        margin: 0px;
        width: 50vw;
    }

    #operation-content #card-3 {
        padding: 30px;
        width: 50vw;
        margin: 0px;
    }
}
@media(max-width: 991px) {
    #operation-content #card-1 {
        width: 60vw;
    }

    #operation-content #card-2 {
        width: 60vw;
    }

    #operation-content #card-3 {
        width: 60vw;
    }
}
@media(max-width: 767px) {
    #operation-content .card-title {
        font-size: 26px;
    }

    #operation-content #card-1 {
        width: 70vw;
        padding: 20px;
    }

    #operation-content #card-2 {
        width: 70vw;
        padding: 20px;
    }

    #operation-content #card-3 {
        width: 70vw;
        padding: 20px;
    }
}
@media(max-width: 575px) {
    #operation-content #card-1 {
        width: 70vw;
        padding: 10px;
    }

    #operation-content #card-2 {
        width: 70vw;
        padding: 10px;
    }

    #operation-content #card-3 {
        width: 70vw;
        padding: 10px;
    }
}
@media(max-width: 425px) {
    #operation-line{
        height: 2px;
    }
    #operation-content .card-title {
        font-size: 22px;
    }
    #operation-content .card-text {
        font-size: 15px;
        font-weight: 300;
    }
    #operation-content #card-1 {
        width: 80vw;
        padding: 0px;
    }

    #operation-content #card-2 {
        width: 80vw;
        padding: 0px;
    }

    #operation-content #card-3 {
        width: 80vw;
        padding: 0px;
    }
}
@media(max-width: 320px) {
    #operation-title {
        text-align: center;
    }

    #operation-line {
        width: 50%;
        height: 2px;
    }
    #operation-content .card-title {
        font-size: 20px;
    }
    #operation-content .card-text {
        font-size: 15px;
    }
}