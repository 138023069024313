.col-lg-5 {
    display: grid;
    align-items: center;
    padding: 0px;
}

.col-lg-7 {
    display: grid;
    padding: 0px;
    align-items: center;
}

.row {
    --bs-gutter-x: 0px
}

#about {
    /*     background: url('../assets/img/about12.png');
 */
    /*  background: linear-gradient(45deg, #a1a1a12a, #ffffff) 0 0 / 1000% no-repeat,url(../assets/img/about12.png) 0 0 / cover no-repeat; */
    width: 100%;
    height: 100vh;
    padding: 120px 0px 100px 0px;
}

#about-x1 {
    display: grid;
    gap: 50px;
}

#about-title {
    justify-content: center;
}

#about-title h1 {
    color: #F7811D;
    font-weight: 500;
}

#about-line {
    width: 150px;
    height: 3px;
    background-color: #9c9c9c;
    border-radius: 20px;
}

#about-content p {
    font-weight: 300;
    box-sizing: border-box;
    margin-top: auto;
    color: rgb(0, 0, 0);
    font-size: 18px;
    width: 100%;
}

#about-image-1 {
    margin: -70px 0px 0px 0px;
    display: flex;
    justify-content: right;
}

#about-image-1 img {
    padding-top: 50px;
    width: 90%;
}

@media screen and (max-width: 1600px) {
    #about {
        height: 100%;
        padding: 80px 0px 80px 0px;
    }

    #about-image-1 img {
        width: 70%;
    }
}

@media screen and (max-width: 991px) {
    #about {
        height: 100%;
    }

    #about-image-1 {
        margin: 0px;
        justify-content: center;
    }
}
@media screen and (max-width: 767px) {
    #about-image {
        display: none;
    }

    .about {
        padding: 50px 0px 50px 0px;
        text-align: center;
        margin: 20px 0px 20px 0px;
    }

    #about-title {
        display: grid;
        justify-content: center;
        text-align: center;
    }

    #about-line {
        margin-top: -8px;
        width: 100%;
    }

    #about-title h1 {
        margin-bottom: 10px;
    }

    #about-content {
        justify-content: center;
    }

    #about-content p {
        justify-self: center;
        padding: 5px;
        margin: 0px !important;
        text-align: center;
        width: 100%;
    }
}
@media screen and (max-width: 575px) {
    .about {
        padding: 50px 0px 50px 0px;
        text-align: center;
        margin: 20px 0px 20px 0px;
    }

    #about-line {
        margin-top: 0px;
        width: 78vw;
    }

    #about-title h1 {
        margin: 0px;
    }

    #text-about {
        justify-content: center;
        width: 100vw;
    }

    #text-about p {
        justify-self: center;
        padding: 5px;
        margin: 0px;
        text-align: center;
        width: 80vw;
    }
}
@media screen and (max-width: 425px) {
    #about {
        padding: 50px 5px 50px 5px;
    }

    #about-x1 {
        gap: 20px;
        text-align: center;
    }

    #about-title {
        display: grid;
        justify-content: center;
        width: 100%;
    }

    #about-title h1 {
        width: 100%;
    }

    #about-line {
        margin-top: 7px;
        width: 100%;
        height: 2px;
    }

    #about-content p {
        margin: 0px;
        justify-self: center;
        padding: 5px;
        font-size: 16px !important;
    }
}
@media screen and (max-width: 375px) {
    #about {
        padding: 50px 5px 50px 5px;
    }

    #about-x1 {
        gap: 20px;
        text-align: center;
    }

    #about-title {
        display: grid;
        justify-content: center;
        width: 100%;
    }

    #about-title h1 {
        width: 100%;
    }

    #about-line {
        margin-top: 7px;
        width: 100%;
    }

    #about-content p {
        margin: 0px;
        justify-self: center;
        padding: 5px;
        font-size: 19px;
    }
}
@media screen and (max-width: 320px) {
    #about {
        padding: 50px 5px 50px 5px;
    }

    #about-x1 {
        gap: 20px;
        text-align: center;
    }

    #about-title {
        display: grid;
        justify-content: center;
        width: 100%;
    }

    #about-line {
        margin-top: 7px;
        width: 100%;
        height: 2px;
    }
    #about-content p {
        margin: 0px;
        justify-self: center;
        padding: 5px;
        font-size: 15px;
    }
}