.col-sm-5 {
    padding: 0px;
}

.col-sm-7 {
    padding: 0px;
}

#info {
    background: rgb(2, 0, 36);
    background: linear-gradient(115deg, #F7811D 65%, #6b6b6b 40%);
    height: 70vh;
    gap: 20px;
}

#info-x1 {
    display: grid;
    align-items: center;
    color: white;
    height: 70vh;
}

#info-x1 h1 {
    color: #ffffff;
    font-weight: 500;
}

#info-x1 ul {
    padding: 0px;
    margin: 0px;
    display: grid;
    gap: 40px;
    font-size: 30px;
    font-weight: 300;
    list-style: none;
}

#info-x1 ul li {
    padding: 5px 0px 0px 0px;
    align-items: center;
    gap: 30px;
    display: flex;
}

#info-x1 ul li p {
    margin: 0px;
}

#info-x1 ul li img {
    height: 40px;
    width: 40px;
}

#info-x2 {
    display: grid;
    align-items: center;
    width: 100%;
    padding: 0px 0px 0px 0px;
    height: 70vh;
}

#info-x2 img {
    display: grid;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: auto;
    height: auto;
    width: 75%;
}
@media(max-width: 1440px) {
    #info {
        background: rgb(2, 0, 36);
        background: linear-gradient(120deg, #F7811D 60%, #404040 40%);
    }

    #info-x1 {
        width: 120%;
    }

    #info-x1 ul {
        padding: 0px 0px 0px 80px;
        margin: 0px;
    }

    #info-x2 img {
        display: block;
        margin-left: auto;
        margin-right: 50px;
    }
}
@media(max-width: 1200px) {
    #info {
        background: rgb(2, 0, 36);
        background: linear-gradient(120deg, #F7811D 60%, #404040 40%);
    }

    #info-x1 {
        width: 120%;
    }

    #info-x1 ul {
        padding: 0px 0px 0px 80px;
        margin: 0px;
    }

    #info-x2 img {
        display: block;
        margin-left: auto;
        margin-right: 50px;
    }
}
@media(max-width: 991px) {
    #info {
        text-align: center;
        height: auto;
        background: rgb(2, 0, 36);
        background: linear-gradient(0deg, #404040 25%, #F7811D 20%) !important;
    }

    #info-x1 {
        width: 100%;
        height: auto;
    }

    #info-x1 ul {
        justify-content: center;
        padding: 100px 5px 0px 5px;
        font-size: 32px;
        margin: 0px;
        gap: 35px;
    }

    #info-x1 ul h1 {
        font-weight: 400;
        margin: 0px 0px 60px 0px;
    }

    #info-x1 ul li img {
        width: 20px;
        height: 20px;
    }

    #info-x2 {
        height: auto;
    }

    #info-x2 img {
        margin: auto;
        width: 70%;
    }
}
@media(max-width: 767px) {
    #info {
        text-align: center;
        height: auto;
        background: rgb(2, 0, 36);
    }

    #info-x1 {
        width: 100%;
        height: auto;
    }

    #info-x1 ul {
        padding: 80px 5px 0px 5px;
        font-size: 30px;
        margin: 0px;
        gap: 35px;
    }

    #info-x1 ul h1 {
        font-weight: 400;
        margin: 0px 0px 40px 0px;
    }

    #info-x1 ul li img {
        width: 20px;
    }

    #info-x2 {
        height: auto;
    }

    #info-x2 img {
        margin: auto;
        width: 70%;
    }
}
@media(max-width: 575px) {
    #info {
        text-align: center;
        height: auto;
        background: rgb(2, 0, 36);
        background: linear-gradient(20deg, #404040 35%, #F7811D 20%);
    }

    #info-x1 {
        width: 100%;
        height: auto;
    }

    #info-x1 ul {
        padding: 60px 5px 0px 5px;
        font-size: 27px;
        margin: 0px;
        gap: 35px;
    }

    #info-x1 ul h1 {
        font-weight: 400;
        margin: 0px 0px 40px 0px;
    }

    #info-x1 ul li {
        gap: 10px;
    }

    #info-x1 ul li img {
        width: 20px;
    }

    #info-x2 {
        height: auto;
    }

    #info-x2 img {
        margin: auto;
        width: 80%;
    }
}
@media(max-width: 425px) {
    #info {
        text-align: center;
        height: auto;
        background: rgb(2, 0, 36);
        background: linear-gradient(20deg, #404040 30%, #F7811D 20%);
    }

    #info-x1 {
        width: 100%;
        height: auto;
    }

    #info-x1 ul {
        padding: 60px 5px 0px 5px;
        font-size: 22px;
        margin: 0px;
        gap: 30px;
    }

    #info-x1 ul h1 {
        font-weight: 400;
        margin: 0px 0px 20px 0px;
    }

    #info-x1 ul li img {
        width: 20px;
    }

    #info-x2 {
        height: auto;
    }

    #info-x2 img {
        margin: auto;
        width: 90%;
    }
}
@media(max-width: 375px) {
    #info {
        text-align: center;
        height: auto;
        background: rgb(2, 0, 36);
        background: linear-gradient(20deg, #404040 30%, #F7811D 20%);
    }

    #info-x1 {
        width: 100%;
        height: auto;
    }

    #info-x1 ul {
        padding: 60px 5px 0px 5px;
        font-size: 22px;
        margin: 0px;
        gap: 30px;
    }

    #info-x1 ul h1 {
        font-weight: 400;
        margin: 0px 0px 20px 0px;
    }

    #info-x1 ul li {
        gap: 5px;
    }

    #info-x1 ul li img {
        width: 20px;
    }

    #info-x2 {
        height: auto;
    }

    #info-x2 img {
        margin: auto;
        width: 90%;
    }
}
@media(max-width: 320px) {
    #info {
        text-align: center;
        height: auto;
        background: rgb(2, 0, 36);
        background: linear-gradient(20deg, #404040 30%, #F7811D 20%);
    }

    #info-x1 {
        width: 100%;
        height: auto;
    }

    #info-x1 ul {
        padding: 40px 5px 0px 5px;
        font-size: 20px;
        margin: 0px;
        gap: 30px;
    }

    #info-x1 ul li img {
        width: 20px;
    }

    #info-x2 {
        height: auto;
    }

    #info-x2 img {
        margin: auto;
        width: 90%;
    }
}