#services {
    background-color: #F7F7F7;
    height: 100vh;
    padding: 120px 0px 150px 0px;
}

#services-title {
    display: grid;
    gap: 0px;
}

#services-title h1 {
    font-weight: 500;
    color: #f7811d;
}

#services-line {
    width: 150px;
    height: 3px;
    background-color: #9c9c9c;
    border-radius: 20px;
}

#services-content p {
    font-weight: 300;
    margin-top: 50px;
    font-size: 25px;
}

#services #card-1,
#services #card-2,
#services #card-3 {
    background: linear-gradient(0deg, #eeeeee 30%, #ff973c);
    width: 50px;
}

#services #card-body {
    padding: 40px 0px 0px 0px;
    display: grid;
    gap: 10px;
}

#services .card-body {
    display: grid;
    gap: 25px;
}

#services #card-group-1 {
    margin-top: 50px;
}

#services .card-text {
    font-weight: 300;
    font-size: 1.1rem;
    text-align: center;
    color: #404040;
}

#services #card-1 {
    box-shadow: 0 8px 32px 0 rgb(34 85 112 / 20%);
    margin: 0px;
    text-align: center;
    display: grid;
    align-items: center;
    width: auto;
    height: auto;
    border-radius: 8px;
    border: 0px solid #F7811D;
    transition: 0.5s;
}

#services #card-1:hover {
    transform: scale(1.05);
}

#services #card-2 {
    box-shadow: 0 8px 32px 0 rgb(34 85 112 / 20%);
    border-radius: 8px;
    margin: 0px 40px 0px 40px;
    border: 2px solid #F7811D;
    text-align: center;
    display: grid;
    align-items: center;
    width: auto;
    height: auto;
    border-radius: 8px;
    border: 0px solid #F7811D;
    transition: 0.5s;
}

#services #card-2:hover {
    transform: scale(1.05);
}

#services #card-3 {
    box-shadow: 0 8px 32px 0 rgb(34 85 112 / 20%);
    text-align: center;
    display: grid;
    align-items: center;
    width: auto;
    height: auto;
    border-radius: 8px;
    border: 0px solid #F7811D;
    transition: 0.5s;
}

#services #card-3:hover {
    transform: scale(1.05);
}

#services .card-img-top {
    margin: 0px auto 0px auto;
    width: 120px;
    height: 120px;
}

#services .card-title.h5 {
    margin-top: 20px;
    font-size: 1.8rem;
    font-weight: 400;
    color: #404040;
}

@media(max-width: 1600px) {
    #services {
        height: 100%;
        padding: 80px 0px 80px 0px;
    }

    #services-content p {
        font-size: 22px;
    }

    #services .card-title.h5 {
        font-size: 1.6rem;
    }

    #services .card-text {
        font-size: 1rem;
    }

    #services #card-body {
        padding: 0px;
    }
}
@media(max-width: 1440px) {
    #services {
        padding: 100px 0px 100px 0px;
    }
}
@media(max-width: 1200px) {
    #services .card {
        background: linear-gradient(0deg, #e9e9e9 60%, #f7811d 40%);
        width: 50px;
    }

    #services .card-body {
        gap: 10px;
    }

    #services .card-text {
        font-size: 15px;
    }
}
@media(max-width: 991px) {
    .container{
        max-width: 90%;
    }
    #serices #text-about {
        text-align: left;
    }

    #services .card-img-top {
        width: 60px;
        height: 61px;
    }

    #services .card-title.h5 {
        margin-top: 10px;
        font-size: 1.5rem;
    }

    #services {
        height: auto;
        padding: 150px 0px 150px 0px;
    }
    .card-body{
        padding: 50px 20px;
    }
}
@media(max-width: 991px) {
    #services {
        text-align: center;
        height: auto;
        padding: 100px 0px 100px 0px;
    }

    #services-title {
        display: grid;
        justify-content: center;
        text-align: center;
    }

    #services-title h1 {
        margin-bottom: 10px;
    }

    #services-line {
        width: 100%;
    }

    #services-content p {
        font-size: 22px;
    }

    #services .card-group {
        padding: 0px;
        margin-top: 50px;
        display: grid;
        gap: 20px;
    }

    #services #card-2 {
        margin: 0px !important;
    }

    #services .card-img-top {
        width: 80px;
        height: 80px;
    }

    #services .card-title.h5 {
        font-size: 26px
    }

    #services .card-text {
        font-size: 18px;
    }

    #services .card {
        background: linear-gradient(0deg, #e9e9e9 62%, #CD8500 40%);
        width: 50px;
    }
}
@media(max-width: 575px) {
    #services {
        padding: 50px 5px 50px 5px;
    }

    #services-x1 {
        gap: 20px;
    }

    #services-x1 p {
        margin: 0px 20px 0px 20px;
        font-size: 20px;
    }

    #services-x1 {
        text-align: center;
    }

    #services .card-title.h5 {
        font-size: 25px;
    }

    #services .card-text {
        font-size: 17px;
    }

    #services #card-2 {
        margin: 0px 0px 12px 0px;
    }

}
@media(max-width: 425px) {
    #services {
        padding: 50px 5px 50px 5px;
    }
    #services-line{
        height: 2px;
    }
    #services-x1 {
        gap: 20px;
    }
    #services-content p{
        font-size: 17px;
    }
    #services .card-title.h5 {
        font-size: 22px;
    }
    #services-x1 p {
        margin: 0px 20px 0px 20px;
        font-size: 17px;
    }

    #services-x1 {
        text-align: center;
    }

    #services .card-text {
        font-size: 17px;
    }

    #services #card-2 {
        margin: 0px 0px 12px 0px;
    }

    #services .card-group {
        padding: 0px 10px 0px 10px;
    }

    #services .card {
        background: linear-gradient(0deg, #e9e9e9 60%, #CD8500 40%);
        width: 50px;
    }
}
@media(max-width: 375px) {
    #services {
        padding: 50px 5px 50px 5px;
    }

    #services-content p {
        margin: 50px 20px 0px 20px;
        font-size: 19px;
    }

    .card-text {
        font-size: 16px;
    }

    #card-2 {
        margin: 0px 0px 12px 0px;
    }

    .card-group {
        padding: 0px 10px 0px 10px;
    }
}
@media(max-width: 320px) {
    #services {
        padding: 50px 5px 50px 5px;
    }
    #services-line{
        height: 2px;
    }
    #services-x1 {
        gap: 20px;
    }

    #services-content p {
        margin: 50px 20px 0px 20px;
        font-size: 15px;
    }
    #services .card-title.h5 {
        font-size: 20px;
    }
    #services-x1 {
        text-align: center;
    }

    #services #card-2 {
        margin: 0px 0px 12px 0px;
    }

    #services .card-text {
        font-size: 15px;
    }

    #services .card-group {
        padding: 0px 10px 0px 10px;
    }

    #services #card-body {
        padding: 80px 0px 0px 0px;
    }
}
