* {
    font-family: 'Encode Sans';
}
p {
    font-family: 'Nunito';
}
html {
    scroll-behavior: smooth;
}
h1 {
    font-size: 55px !important;
}

/* ----------------------------------------Media-------------------------------------------- */
@media(max-width: 1600px) {
    #cont{
        gap: 700px;
    }
    h1 {
        font-size: 45px !important;
    }

    h2 {
        font-size: 30px !important;
    }
}


@media(max-width:1200px) {
    #cont{
        gap: 500px !important;
    }
}

@media(max-width:767px) {

    h1 {
        font-size: 50px !important;
    }
}

@media(max-width:575px) {
    h1 {
        font-size: 45px !important;
    }
}

@media(max-width: 425px) {
    h1 {
        font-size: 28px !important;
    }
    h2{
        font-size: 22px !important;
    }

}

@media(max-width: 375px) {
    h1{
        font-size: 25px !important;
    }
    h2{
        font-size: 20px !important;
    }
    p{
        font-size: 16px !important;
    }
}

@media(max-width: 320px) {
    h1 {
        font-size: 25px !important;
    }
}