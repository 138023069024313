#cont{
    gap:600px;
}
.navbar{
    height: 11vh;
    padding: 0;
}
.py-3{
    margin: 0px !important;
    padding-top: 0 !important;
    padding-bottom: 0!important;
}
.navbar-brand{
    padding-top: 0;
    padding-bottom: 0;
}
.navbar-nav .nav-link.active{
    color: #3d3d3d;
}
#nav{
    position:fixed;
    width: 100%;
    z-index: 10;
}
#logoMGA img{
    filter: brightness(100);
    width: 130px;
    height: 130px;
}
#menu{
    gap: 25px;
}
a.nav-link{
    color: #ffffff;
    font-size: 18px;
    font-weight: 400 !important;
    font-size: 22px;
    text-decoration: none;
}
a.nav-link:hover{
    color: #3d3d3d;
}
.navbar-toggler{
    border: var(--bs-border-width) solid #f7831db2;
}
.text-underlined-nav{
    width: 100%;
    position: relative;
    overflow: hidden;
    color: var(--cor-branco);
    will-change: color;
    transition: color .25s ease-out;  
    display: flex;
  }
  .text-underlined-nav::before, 
  .text-underlined-nav::after{
    content: "";
    width: 0;
    height: 2px;
    background-color: var(--textUnderlinedLineColor, currentColor);
    color: var(--cor-branco);
    will-change: width;
    transition: width .15s ease-in-out;
    position: absolute;
    bottom: 0;
  }
  .text-underlined-nav:hover::before, 
  .text-underlined-nav:hover::after{
    width: 100%;
    transition-duration: .5s;
  }

  @media screen and (max-width: 1600px){
    #logoMGA img {
        width: 100px;
        height: 100px;
    }

    #menu {
        gap: 15px;
    }

    a.nav-link {
        font-size: 16px;
    }
  }

  @media screen and (max-width: 767px){
    .container{
        max-width: 100%;
    }
    #cont.container{
        justify-content: space-around;
        margin: 0px;
        padding: 0px;
        width: 100%;
    }
    #logoMGA img{
        width: 80px;
        height: 80px;
    }
    .navbar-collapse{
        display: grid;
        justify-content: center;
        text-align: center;
    }
    #menu{
        color: white;
        background-color: #F7811D;
        /* background-image: linear-gradient(to right, #ffffff3b, #F7811D, #F7811D, #F7811D, #ffffff31); */
        padding: 10px;
        width: 100vw;
    }
    .text-underlined-nav{
        display: grid;
    }
    a.nav-link{
        color: white;
    }
    .navbar-nav .nav-link.active {
        color: #ffffff;
    }
  }