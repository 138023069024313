.alert-warning img{
    height: 20px;
    width: 20px;
}
.alert-success img{
    height: 20px;
    width: 20px;
}
.alert-warning{
    font-size: 17px;
    display: flex;
    gap: 5px;
    align-items: center;
    width: 300px;
    color:#F7811D;
    background-color: #ffebc5;
}
.alert-success{
    font-size: 17px;
    display: flex;
    gap: 5px;
    align-items: center;
    color:#00310b;
    background-color: #cbffca;
}
.col-lg-7 {
    padding: 0px;
}

#contact {
    height: auto;
    padding: 100px 0px 0px 0px;
}

#contact-title {
    display: grid;
    gap: 0px;
}

#contact-title h1 {
    color: #404040;
    font-weight: 500;
}

#contact-line {
    width: 150px;
    height: 3px;
    background-color: #F7811D;
    border-radius: 20px;
}

#contact-content p {
    font-weight: 300;
    color: #404040;
    font-size: 25px;
    margin-top: 50px;
}

#form {
    margin-top: 50px;
}

#contact2 {
    display: grid;
    gap: 30px;
    padding: 50px 0px 100px 0px;
    color: #F7811D;
}

#contact2 h2 {
    display: flex;
    font-size: 35px;
}

#contact2 ul {
    color: #404040;
    padding: 0px;
    gap: 200px;
    display: flex;
    list-style: none;
    font-size: 25px;
}

#gif {
    height: 100%;
    display: grid;
    align-items: center;
}

#gif img {
    display: block;
    margin: 75px auto 0px 70px;
    width: 100%;
}

#button-form {
    width: 150px;
    height: 45px;
    background-color: #F7811D;
    transition: 0.2s;
}

#button-form:hover {
    transform: scale(1.1);
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #ffffff;
    --bs-btn-border-color: #f7811d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #f7811d;
    --bs-btn-hover-border-color: #f7811d;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #f7811d;
    --bs-btn-active-border-color: #f7811d;
    --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #f7811d;
    --bs-btn-disabled-border-color: #f7811d;
}

.form-control {
    border: 1px solid #F7811D;
}

@media screen and (max-width: 1600px) {
    #info-x1 ul li img {
        height: 30px;
        width: 30px;
    }
}
@media screen and (max-width: 1440px) {
    #gif img {
        display: block;
        margin: 75px auto 0px 50px;
        width: 100%;
    }
}
@media screen and (max-width: 1200px) {
    #gif img {
        display: block;
        margin: 75px auto 0px 0px;
        width: 100%;
    }
}
@media screen and (max-width: 991px) {
    #gif {
        display: none;
    }

    #contact2 ul {
        display: grid;
        font-size: 25px;
        gap: 15px;
    }
}
@media screen and (max-width: 767px) {
    #contact {
        text-align: center;
    }

    #contact2 h2 {
        display: grid;
        text-align: center;
    }

    #contact-title {
        display: grid;
        justify-content: center;
    }

    #contact-line {
        width: 100%;
    }
}
@media screen and (max-width: 425px) {
    #form{
        scale: 0.9;
    }
    #contact-line{
        height: 2px;
    }
    #contact-content p {
        font-size: 17px;
    }
    #contact2 ul{
        font-size: 17px;
    }
}
@media screen and (max-width: 375px) {
    #contact-content p {
        font-size: 19px;
    }
}
@media screen and (max-width: 320px) {
    #contact-content p {
        font-size: 15px;
    }
    #contact-line{
        height: 2px;
    }
    #contact2 ul li {
        font-size: 15px;
    }

    #contact2 h2 {
        font-size: 20px !important;
    }
}