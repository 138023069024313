.carousel-indicators{
    display: none;
}
.carousel-item{
    height: 100vh;
    width: 100%;
}
.w-100{
    object-fit: cover !important;
}
.carousel-caption{
    bottom: 8rem !important;
}
/* .carousel-control-next{
    display: none;
} */
.carousel-control-next-icon{
/*     display: none;
 */    filter: brightness(100);
    scale: 1.2;
}
/* .carousel-control-prev{
    display: none;
} */
.carousel-control-prev-icon{
/*     display: none;
 */    filter: brightness(100);
    scale: 1.2;
}
.carousel-caption h3{
    font-size: 3rem;
    font-family: 'Roboto';
    color: rgb(255, 255, 255);
}
.carousel-caption p{
    font-size: 2rem;
    font-family: 'Roboto';
    color: rgb(255, 255, 255);
}
.text-logo{
    width: 100%;
    justify-content: center ;
}
.text-logo p{
    text-align: center;
    margin: 0px;
    padding: 0;
    width: 390px;
    font-size: 20px;
    color: black;
}

@media(max-width: 1600px) {
    
    .carousel-caption h3 {
        font-size: 2.4rem !important;
    }

    .carousel-caption p {
        font-size: 1.8rem;
    }
}

@media screen and (max-width: 1440px){
    .carousel-item{
        height: auto;
    }
    .carousel-caption {
        bottom: 4rem !important;
    }

    .carousel-caption h3 {
        font-size: 2rem !important;
    }

    .carousel-caption p {
        font-size: 2rem !important;
    }
}

@media screen and (max-width: 1440px){
    .carousel-item{
        height: auto;
    }
    .carousel-caption {
        bottom: 4rem !important;
    }

    .carousel-caption h3 {
        font-size: 2rem !important;
    }

    .carousel-caption p {
        font-size: 2rem !important;
    }
}

@media screen and (max-width: 991px){
    #cont{
        gap: 200px !important;
    }
    .w-100 {
        height: 50vh;
    }

    .carousel-caption {
        bottom: 3rem !important;
    }

    .carousel-caption h3 {
        font-size: 2rem !important;
    }

    .carousel-caption p {
        font-size: 1.6rem !important;
    }
}

@media screen and (max-width: 767px){
    #cont{
        gap: 0px !important;
    }
    .w-100 {
        height: 55vh;
    }

    .carousel-control-next-icon {
        scale: 1.5;
    }

    .carousel-control-prev-icon {
        scale: 1.5;
    }
}

@media screen and (max-width: 575px){
    .carousel-caption h3 {
        font-size: 2rem !important;
    }

    .carousel-caption p {
        font-size: 1.2rem !important;
    }

    .w-100 {
        width: 100% !important;
        height: 45vh !important;
    }

    .carousel-caption {
        bottom: 1rem !important;
    }

    .logo img {
        margin-left: auto;
        margin-right: auto;
    }

    #cont {
        gap: 50px !important;
    }

    #menu {
        margin-top: -50px;
        align-items: center;
        gap: 10px;
    }

    .carousel-caption {
        bottom: 1.5rem !important;
    }
}

@media screen and (max-width: 425px){
    .carousel-caption h3 {
        font-size: 1.5rem !important;
    }

    .carousel-caption p {
        font-size: 1rem !important;
    }

    .carousel-control-next-icon {
        scale: 1.4;
    }

    .carousel-control-prev-icon {
        scale: 1.4;
    }
}

@media screen and (max-width: 375px){
    .carousel-control-next-icon {
        scale: 1.2;
    }

    .carousel-control-prev-icon {
        scale: 1.2;
    }
}

@media screen and (max-width: 320px){
    .carousel-control-next-icon {
        scale: 1
    }

    .carousel-control-prev-icon {
        scale: 1
    }
}