#footer {
    background-color: rgb(247, 247, 247);

}

footer {
    padding: 40px 0px 0px 0px;
}

footer ul {
    padding: 0px;
}

footer li {
    font-size: 18px;
}

footer h2 {
    margin-bottom: 15px;
    font-size: 25px;
    font-weight: 400;
}

#footer-1 {
    margin: 0;
    gap: 50px;
    width: 100%;
    padding: 0px 0px 40px 0px;
}

.footer-x1 {
    margin: 20px;
    display: grid;
    color: black;
    width: 100%;
}

.footer-x1 p {
    text-align: left;
    font-size: 17px;
}

.footer-x1 img {
    display: block;
    margin-left: 0;
    margin-right: auto;
    width: 150px;
    height: 150px;
}

.footer-x2 {
    margin: 50px 0px 0px 100px;
    width: 100%;
    display: block;
}

.footer-x2 ul {
    list-style: none;
}

.footer-x2 a {
    text-decoration: none;
    color: black;
}

.footer-x2 li {
    margin-top: 6px;
}

.footer-x3 {
    margin: 50px;
    width: 100%;
    display: block;
}

.footer-x3 li {
    margin-top: 6px;
}

.footer-x3 ul {
    list-style: none;
}

.footer-x4 {
    width: 100%;
    display: block;
    margin-top: 50px;
}
.footer-x4 p {
    color: black;
    font-size: 18px;
}

#footer-2 {
    height: 100%;
    padding: 0;
    text-align: center;
    background-color: #F7811D;
}

#footer-2 p {
    text-align: center;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0px;
    color: white;
    font-size: 1rem;
    align-self: center;
}

#footer-2 a {

    padding: 0;
    text-decoration: none;
    color: white;
}

.text-underlined-footer {
    width: 100%;
    position: relative;
    overflow: hidden;
    color: var(--cor-branco);
    will-change: color;
    transition: color .25s ease-out;
    display: flex;
}

.text-underlined-footer::before,
.text-underlined-footer::after {
    content: "";
    width: 0;
    height: 2px;
    background-color: var(--textUnderlinedLineColor, currentColor);
    color: #F7811D;
    will-change: width;
    transition: width .1s ease-out;
    position: absolute;
    bottom: 0;
}

.text-underlined-footer:hover::before,
.text-underlined-footer:hover::after {
    width: 15%;
    transition-duration: .5s;
}

@media screen and (max-width: 1440px){
    .footer-x1 {
        text-align: center;
    }
}
@media screen and (max-width: 991px){
    #footer-1 {
        gap: 0;
        text-align: center;
    }

    .footer-x1 {
        text-align: center;
        margin: 0px 0px 30px 0px;
    }

    .footer-x1 a {
        height: 120px;
    }

    .footer-x1 img {
        margin: auto;
        scale: 0.8;
    }

    .footer-x1 p {
        text-align: center;
        margin-top: -20px;
        font-size: 20px;
        width: 100%;

    }

    .footer-x2 {
        margin: 25px 0px 0px 0px;
    }

    .footer-x2 li {
        width: 100%;
    }

    .footer-x3 {
        margin: 25px 0px 30px 0px;
    }

    .footer-x4 {
        margin: 25px 0px 0px 0px;
    }

    #footer-2 {
        width: 100vw;
        margin: 0;
    }

    #footer-2 p {
        width: 100vw;
    }
}
@media screen and (max-width: 767px){
    #footer-1 {
        text-align: center;
        gap: 0px;
    }

    .footer-x1 {
        gap: 20px;
        width: 100%;
        justify-content: center;
    }

    .logo img {
        margin-left: auto;
        margin-right: auto;
    }

    .footer-x1 p {
        font-size: 19px;
        text-align: center;
        width: 100%;
    }

    .text-logo {
        width: 80vw;
    }

    .text-logo p {
        font-size: 20px;
    }

    .footer-x2 {
        width: 100%;
        margin: 50px 0px 0px 0px;
    }

    .footer-x3 {
        width: 100%;
        margin: 50px 0px 40px 0px;
    }

    .footer-x4 {
        width: 100%;
        margin: 14px 0px 40px 0px;
    }

    #footer-2 {
        margin: 0;
        width: 100vw;
    }

    #footer-2 p {
        font-size: 1rem;
        width: 100vw;
    }
}
@media screen and (max-width: 575px){
    .footer-x1 {
        gap: 20px;
    }

    .text-logo {
        width: 100%;
    }

    .text-logo p {
        width: 90vw;
    } 
}
@media screen and (max-width: 425px){
    #footer-1 {
        gap: 20px;
        padding: 20px;
    }
    #footer li{
        font-size: 17px;
    }
    .footer-x1 {
        gap: 15px;
        margin: 0px 0px 15px 0px;
        text-align: center;
    }

    .footer-x1 p {
        font-size: 17px;
    }

    .footer-x2 {
        margin: 20px 0px 0px 0px;
        text-align: center;
    }

    .footer-x3 {
        margin: 0px;
        text-align: center;
    }

    .footer-x4 {
        margin: 0px;
        text-align: center;
    }

    .footer-x4 p {
        font-size: 17px;
    }

    .logo img {
        scale: 0.8;
        margin-left: auto;
        margin-right: auto;

    }

    .text-logo {
        width: 100%;
    }

    .text-logo p {
        width: 300px;
        text-align: center;
    }

    #footer-2 {
        height: 100%;
        width: 100vw;
        margin: 0;
    }

    #footer-2 p {
        text-align: center;
        font-size: 14px;
        width: 100vw;
    }
}
@media screen and (max-width: 375px){
    .footer-x2 {
        margin: 20px 0px 0px 0px;
        text-align: center;
    }

    .text-logo {
        width: 100%;
    }

    .text-logo p {
        font-size: 19px;
        width: 311px;
    }

    .logo img {
        margin-left: auto;
        margin-right: auto;
    }

    .footer-x1 {
        gap: 15px;
        margin: 0px 0px 15px 0px;
        text-align: center;
    }

    #footer-2 {
        margin: 0;
        width: 100%;
    }

    #footer-2 p {
        width: 375px;
        font-size: 13px;
    }
}
@media screen and (max-width: 320px){
    #form{
        scale: 0.9;
    }
    #footer h2{
        font-size: 20px !important;
    }
    #footer li{
        font-size: 15px ;
    }
    #footer-1 {
        gap: 10px;
        padding: 20px;
    }

    .footer-x1 {
        gap: 15px;
        margin: 0px 0px 15px 0px;
        text-align: center;
    }

    .footer-x1 p {
        font-size: 15px;
    }

    .footer-x2 {
        margin: 20px 0px 0px 0px;
        text-align: center !important;
    }

    .footer-x3 {
        margin: 0px;
        text-align: center;
    }

    .footer-x4 {
        margin: 0px;
        text-align: center;
    }

    .footer-x4 p {
        font-size: 15px;
    }

    .logo img {
        margin-left: auto;
        margin-right: auto;
        scale: 0.8;
    }

    .text-logo p {
        width: 100%;
        text-align: center;
    }

    #footer-2 {
        margin: 0;
        width: 100%;
    }

    #footer-2 p {
        text-align: center;
        font-size: 11px;
        width: 320px;
    }
}